<template>
    <div>
        <label :for="id" class="px-1 text-sm text-gray-600 font-bold">
            {{ label }}
            <span v-if="required">*</span>
        </label>
        <v-select :class="inputClasses"
                  :options="options"
                  :multiple="isMultiSelect"
                  v-model="selectedValues"
                  label="caption"
                  track-by="id"
                  :reduce="option => option.id"
                  @input="updateValue"
                  :clearable="isMultiSelect">
        </v-select>
        <span class="text-gray-600 text-xs mt-1">
          {{ description }}
        </span>        
        <div v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: "select_single_dropdown"
        },
        value: {
            default: null
        },
        description: {
            type: String,
            default: ""
        },        
        required: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            required: true
        },
        error: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            selectedValues: this.isMultiSelect ? this.initialSelectedValues : this.value,
        };
    },    
    computed: {
        inputClasses() {
            return {
                "border-red-500": this.error
            };
        },
        isMultiSelect() {
            return this.type === "select_multi_dropdown";
        },
        initialSelectedValues() {
            return this.options.filter(option => Array.isArray(this.value) && this.value.includes(option.id));
        },
    },
    watch: {
        value(newValue) {
            this.selectedValues = this.isMultiSelect ? this.options.filter(option => Array.isArray(newValue) && newValue.includes(option.id)) : newValue;
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value);
        },
    }
}
</script>
