<template>
    <div>
        <label :for="id" class="px-1 text-sm text-gray-600 font-bold">
            {{ label }}
            <span v-if="required">*</span>
        </label>
        <input :class="inputClasses"
            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
            :id="id"
            :value="formattedValue"
            :type="inputType"
            :placeholder="placeholder"
            @input="emitValue" />
        <span class="text-gray-600 text-xs mt-1">
            {{ description }}
        </span>            
        <div v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number],
            default: ''
        },
        description: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        error: {
            type: String,
            default: ""
        }
    },
    computed: {
        inputClasses() {
            return {
                "border-red-500": this.error
            }
        },
        inputType() {
            switch (this.type) {
                case "int":
                    return "number";
                default:
                    return "text";
            }
        },
        formattedValue() {
            return this.value !== null ? this.value.toString() : '';
        }
    },
    methods: {
        emitValue(event) {
            let value = event.target.value;
            if (!value) {
                this.$emit('input', null);
            } else {
                this.$emit('input', this.inputType === "number" ? Number(value) : value);
            }
        }
    }    
}
</script>