<template>
    <div>
        <div v-if="show" class="min-w-60 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-auto my-6 mx-auto max-w-6xl">
                <!--content-->
                <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">
                        {{ title }}
                    </h3>
                    <button 
                        v-on:click="toggle()"
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 py-2 flex-auto">
                    <div v-if="icon" class="items-center">    
                        <font-awesome-icon :icon="icon" :spin="spin" size="3x" class="text-primary block m-auto"/>
                    </div>                    
                    <p class="my-4 text-lg leading-relaxed">
                        <slot></slot>
                    </p>
                </div>
                <!--footer-->
                <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button type="button" 
                        class="text-primary bg-transparent border border-solid border-primary hover:bg-primary hover:text-white active:bg-primary disabled:text-gray-400 disabled:border-gray-400 disabled:bg-white font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        v-on:click="toggle()"
                        v-html="button">
                    </button>                    
                </div>
                </div>
            </div>
        </div>
        <div v-if="show" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        show: false,
    }
  },
  props: {
      title: String,
      text: String,
      icon: String,
      button: {
          type: String,
          default: "OK",
      },
  },
  methods: {
      toggle: function() {
          this.show = !this.show;
          if (this.show === false) {
            this.$emit('closed');
          }
      },
  },
  computed: {
      spin: function() {
          return (this.icon === "spinner");
      }
  }
}
</script>