<template>
    <div class="container w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 mx-auto flex flex-col py-6 px-3">
        <h2 class="text-3xl text-primary md:text-4xl font-medium mb-2">
            Mijn gegevens
        </h2>
        <div v-if="showForm" class="font-base text-black" v-html="config.contactinfo.description[lang]">
        </div>
        <div v-if="showForm" class="relative flex flex-wrap">
            <div class="w-full relative">
                <div class="md:mt-6">
                    <form class="mt-8" @submit.prevent="updateContactInfo(local);">
                        <div class="mx-auto">
                            <div class="-mx-3 md:flex mb-6">
                                <div v-if="formConfig.fields.customertitleid.visible"
                                    class="md:w-full py-1 px-3">
                                    <span class="px-1 text-sm text-gray-600 font-bold">
                                        {{ formConfig.fields.customertitleid.label[lang] }}
                                        <span v-if="formConfig.fields.customertitleid.required">*</span>
                                    </span>
                                    <select v-model="local.contact.customertitleid"
                                            :required="formConfig.fields.customertitleid.required"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <option disabled value="">{{ 'Maak keuze' }}</option>
                                        <option v-for="title in $store.state.contactconfig.contacttitles"
                                                v-bind:key="title.id"
                                                :value="title.id">{{ title.name }}</option>
                                    </select>
                                    <span class="text-gray-600 text-xs mt-1">
                                        {{ formConfig.fields.customertitleid.description[lang] }}
                                    </span>
                                </div>
                                <div v-if="formConfig.fields.firstname.visible"
                                    class="md:w-full py-1 px-3" 
                                    v-bind:class="{ error: !isValid('firstname') }">
                                    <span class="px-1 text-sm text-gray-600 font-bold">
                                        {{ formConfig.fields.firstname.label[lang] }}
                                        <span v-if="formConfig.fields.firstname.required">*</span>
                                    </span>
                                    <input placeholder="" type="text" id="firstname"
                                        v-model="local.contact.firstname"
                                        :required="formConfig.fields.firstname.required"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    <span class="text-gray-600 text-xs mt-1">
                                        {{ formConfig.fields.firstname.description[lang] }}
                                    </span>
                                </div>
                                <div v-if="formConfig.fields.middlename.visible" class="md:w-full py-1 px-3" id="tm-contactinfo-middlename">
                                    <span class="px-1 text-sm text-gray-600 font-bold">
                                        {{ formConfig.fields.middlename.label[lang] }}
                                    </span>
                                    <input placeholder="" type="text" id="middlename"
                                        v-model="local.contact.middlename"
                                        :required="formConfig.fields.middlename.required"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    <span class="text-gray-600 text-xs mt-1">{{ formConfig.fields.middlename.description[lang] }}</span>
                                </div>

                                <div v-if="formConfig.fields.lastname.visible" class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('lastname') }">
                                    <span class="px-1 text-sm text-gray-600 font-bold">
                                        {{ formConfig.fields.lastname.label[lang] }}
                                        <span v-if="formConfig.fields.lastname.required">*</span>
                                    </span>
                                    <input placeholder="" type="text" id="lastname"
                                        v-model="local.contact.lastname"
                                        :required="formConfig.fields.lastname.required"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    <span class="text-gray-600 text-xs mt-1">{{ formConfig.fields.lastname.description[lang] }}</span>
                                </div>
                            </div>

                            <div v-for="(address, index) in local.addresses" :key="address.id">
                                <div v-if="formConfig.fields.street1.visible" class="-mx-3 md:flex md:flex-row mb-6">                               
                                    <div class="md:w-1/2 py-1 px-3" v-bind:class="{ error: !isValid('street1', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            {{ formConfig.fields.street1.label[lang] }}
                                            <span v-if="formConfig.fields.street1.required">*</span>
                                        </span>
                                        <input placeholder="" type="text" id="street1" 
                                            v-model="local.addresses[index].street1"
                                            :required="formConfig.fields.street1.required"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <span class="text-gray-600 text-xs mt-1">
                                            {{ formConfig.fields.street1.description[lang] }}
                                        </span>
                                    </div>
                                    <div v-if="formConfig.fields.street2.visible" class="md:w-1/4 py-1 px-3" v-bind:class="{ error: !isValid('street2', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            {{ formConfig.fields.street2.label[lang] }}
                                            <span v-if="formConfig.fields.street2.required">*</span>
                                        </span>
                                        <input placeholder="" type="text" id="street2" 
                                            v-model="local.addresses[index].street2"
                                            :required="formConfig.fields.street2.required"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <span class="text-gray-600 text-xs mt-1">{{ formConfig.fields.street2.description[lang] }}</span>
                                    </div>
                                    <div v-if="formConfig.fields.street3.visible" class="md:w-1/4 py-1 px-3">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            {{ formConfig.fields.street3.label[lang] }}
                                        </span>
                                        <input placeholder="" type="text" id="street3" 
                                            v-model="local.addresses[index].street3"
                                            :required="formConfig.fields.street3.required"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <span class="text-gray-600 text-xs mt-1">
                                            {{ formConfig.fields.street3.description[lang] }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="formConfig.fields.zip.visible" class="-mx-3 md:flex mb-6">                               
                                    <div class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('zip', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            {{ formConfig.fields.zip.label[lang] }}
                                            <span v-if="formConfig.fields.zip.required">*</span>
                                        </span>
                                        <input placeholder="" type="text" id="zip" 
                                            v-model="local.addresses[index].zip"
                                            :required="formConfig.fields.zip.required"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <span class="text-gray-600 text-xs mt-1">
                                            {{ formConfig.fields.zip.description[lang] }}
                                        </span>
                                    </div>
                                    <div v-if="formConfig.fields.city.visible" class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('city', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            {{ formConfig.fields.city.label[lang] }}
                                            <span v-if="formConfig.fields.city.required">*</span>
                                        </span>
                                        <input placeholder="" type="text" id="city" 
                                            v-model="local.addresses[index].city"
                                            :required="formConfig.fields.city.required"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <span class="text-gray-600 text-xs mt-1">{{ formConfig.fields.city.description[lang] }}</span>
                                    </div>
                                </div>
                                <div v-if="formConfig.fields.countrycode.visible" class="-mx-3 md:flex mb-6">                              
                                    <div class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('countrycode', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            {{ formConfig.fields.countrycode.label[lang] }}
                                            <span v-if="formConfig.fields.countrycode.required">*</span>
                                        </span>
                                        <select v-model="local.addresses[index].countrycode"
                                            :required="formConfig.fields.countrycode.required"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                            <option disabled value="">{{ 'Maak keuze'[lang] }}</option>
                                            <option v-for="country in $store.state.contactconfig.countries"
                                                :key="country.code" 
                                                :value="country.code">{{ country.name }}</option>
                                        </select>
                                        <span class="text-gray-600 text-xs mt-1">
                                            {{ formConfig.fields.countrycode.description[lang] }}
                                        </span>                                         
                                    </div>
                                </div>
                            </div>

                            <div v-for="(phonenumber, index) in local.phonenumbers" :key="phonenumber.id">                            
                                <div v-if="formConfig.fields.phonenumber.visible" class="-mx-3 md:flex mb-6">                              
                                    <div class="md:w-1/2 py-1 px-3" v-bind:class="{ error: !isValid('number', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            {{ formConfig.fields.phonenumber.label[lang] }}
                                            <span v-if="formConfig.fields.phonenumber.required">*</span>
                                        </span>
                                        <input placeholder="" type="text" id="number" 
                                            v-model="local.phonenumbers[index].number"
                                            :required="formConfig.fields.phonenumber.required"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <span class="text-gray-600 text-xs mt-1">
                                            {{ formConfig.fields.phonenumber.description[lang] }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div v-if="formConfig.fields.birthdate.visible" class="-mx-3 md:flex mb-6">
                                <div class="md:w-1/2 py-1 px-3" v-bind:class="{ error: !isValid('birthdate') }">
                                    <span class="px-1 text-sm text-gray-600 font-bold">
                                        {{ formConfig.fields.birthdate.label[lang] }}
                                        <span v-if="formConfig.fields.birthdate.required">*</span>
                                    </span>
                                    <input placeholder="" type="date" id="birthdate" name="birthdate" 
                                        v-model="local.contact.birthdate"
                                        :required="formConfig.fields.birthdate.required && !birthdatePreferNotToSay"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    <div v-if="formConfig.fields.birthdate.required" class="mt-2">
                                        <input type="checkbox" id="birthdatePreferNotToSay" v-model="birthdatePreferNotToSay">
                                        <label for="birthdatePreferNotToSay" class="ml-2 text-sm text-gray-600">
                                            {{ formConfig.fields.birthdate.preferNotToSay[lang] }}
                                        </label>
                                    </div>
                                    <span class="text-gray-600 text-xs mt-1">{{ formConfig.fields.birthdate.description[lang] }}</span>
                                </div>
                            </div>
                            
                            <div v-if="formConfig.fields.languagecode.visible" class="-mx-3 md:flex mb-6">
                                <div class="md:w-1/2 py-1 px-3">
                                    <span class="px-1 text-sm text-gray-600 font-bold">
                                        {{ formConfig.fields.languagecode.label[lang] }}
                                    </span>
                                    <select v-model="local.contact.languagecode"
                                        :required="formConfig.fields.languagecode.required"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <option disabled value="">{{ 'Maak keuze'[lang] }}</option>
                                        <option value="NL">Nederlands</option>
                                        <option value="EN">Engels</option>
                                    </select>
                                    <span class="text-gray-600 text-xs mt-1">
                                        {{ formConfig.fields.languagecode.description[lang] }}
                                    </span>
                                </div>
                            </div>
                      
                            <template v-for="field in customfields">
                            <div v-if="shouldShowCustomField(field)" :key="field.id" class="-mx-3 md:flex mb-6">
                                <div class="md:w-1/2 py-1 px-3">
                                    <component 
                                        :is="detect(field.fieldtype)"
                                        :id="field.id"
                                        :label="getLabelOfCustomField(field)"
                                        :key="field.key"
                                        :options="field.customfieldvalues"
                                        :type="field.fieldtype"
                                        :required="isRequiredCustomField(field)"
                                        :error="getError(field.fullkey)"
                                        :description="getDescriptionOfCustomField(field)"
                                        v-model="local.contact[field.fullkey]"                                        
                                    />
                                </div>
                            </div>
                            </template>
                            <div class="mx-3 flex mb-6">
                                <button type="submit"
                                    :disabled="!dirty || !valid"
                                    class="mt-3 text-lg font-semibold bg-gray-800 text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black disabled:bg-inactive">
                                    Opslaan
                                </button>
                                <button
                                    @click="$router.push('info')"
                                    class="mt-3 ml-3 text-lg font-semibold bg-gray-200 text-gray-800 rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black">
                                    Terug
                                </button>
                            </div>                           
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="isLoading">
            <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
        </div>
        <div v-if="error">
            <p>
                Er ging iets mis.
            </p>
            <p>
                Probeer het opnieuw of neem contact met ons op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </div>        
        <Modal ref="confirmationModal" title="Gegevens gewijzigd" icon="check-circle">
            Je persoonlijke gegevens zijn succesvol opgeslagen.
        </Modal>
        <Modal ref="errorModal" title="Niet gelukt" icon="exclamation-circle">
            <p class="text-lg">
                Er ging iets mis.
            </p>
            <p class="text-lg">
                Probeer het opnieuw of neem contact met ons op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </Modal>
    </div>      
</template>

<style lang="scss" scoped>
div.error {
    span {
        @apply text-error;
    }

    input {
        @apply border-error;
    }
}
</style>

<script>
import i18n from '@/plugins/i18n'
const moment = require("moment")
moment.locale(i18n.locale)

import { mapGetters } from 'vuex'
import Modal from '../components/Modal.vue'
import DateField from '../components/forms/DateField.vue'
import InputField from '../components/forms/InputField.vue'
import SelectField from '../components/forms/SelectField.vue'
import CheckBoxField from '../components/forms/CheckBoxField.vue'
const R = require('ramda')

const otherContactFields    = ['birthdate'];

export default {
    name: 'Home',
    title: 'Mijn Gegevens',
    components: {
        Modal,
        CheckBoxField,
        DateField,
        InputField,
        SelectField,
    },
  data() {
    return {
        formConfig: null,
        dirty: false,
        birthdatePreferNotToSay: false,
        local: {},
        isLoading: false,
        error: null,
    }
  },
  computed: {
      ...mapGetters(["config", "contact"]),
      showForm: function() {
          return this.isLoading === false && this.error === null && this.local.contact;
      },
      firstnameLabel: function() {
          if (this.config.initials) {
              return "Voorletter(s)";
          }
          return "Voornaam";
      },
      customfields: function() {
        const activatedFields = this.config.contactinfo.customFields;
        return this.$store.state.customfields.fields
            .filter(f => activatedFields.includes(f.key))
            .map(f => {
                f.fullkey = `c_${f.key}`;
                return f;
            })
            // sort in order of activatedFields
            .sort((a, b) => activatedFields.indexOf(a.key) - activatedFields.indexOf(b.key));
      },
      requiredContactFields: function() {
          const contactFields = ['customertitleid', 'firstname', 'lastname', 'birthdate'];
          return contactFields.filter(f => this.formConfig.fields[f].required);
      },
      requiredAddressFields: function() {
        const addressFields = ['street1', 'street2', 'street3', 'zip', 'city', 'countrycode'];
        return addressFields.filter(f => this.formConfig.fields[f].required);
        
      },
      requiredPhonenumberFields: function() {
        if (this.formConfig.fields.phonenumber.required) {
            return ['number'];
        }
        return [];
      },
      requiredCustomFields: function() {
        return Object.entries(this.formConfig.customfields)
            .filter(([, f]) => f.required === true)
            .map(([key]) => `c_${key}`);
      },
      valid: function() {
          const contactFieldsValid = this.requiredContactFields.every(this.isValid);
          const contactOptionalFieldsValid = otherContactFields.every(this.isValid);          
          const addressesValid = this.local.addresses.every(this.isAddressValid);
          const phonenumbersValid = this.local.phonenumbers.every(this.isPhonenumberValid);
          const customFieldsValid = this.customfields.every(f => this.isValid(f.fullkey));
          return contactFieldsValid && contactOptionalFieldsValid && addressesValid && phonenumbersValid && customFieldsValid;
      },      
  },
  methods: {
    isValid: function(key, index) {
        if (this.requiredContactFields.includes(key)) {
            if (key === 'birthdate') {
                // If 'birthdate' is required and 'preferNotToSay' is not checked, check if 'birthdate' is filled in and in the past
                if (this.formConfig.fields.birthdate.required && !this.birthdatePreferNotToSay) {
                    return !!this.local.contact.birthdate && moment(this.local.contact.birthdate) <= moment();
                }
                // If 'preferNotToSay' is checked, the field is considered valid, regardless of the value of 'birthdate'
                return this.birthdatePreferNotToSay || !!this.local.contact.birthdate;
            }            
            return !!this.local.contact[key];
        }
        if (this.requiredCustomFields.includes(key)) {
            if (Array.isArray(this.local.contact[key]) && this.local.contact[key].length === 0) {
                return false;
            }
            return !!this.local.contact[key];
        }
        if (this.requiredAddressFields.includes(key)) {
            return !!this.local.addresses[index][key];
        }
        if (this.requiredPhonenumberFields.includes(key)) {
            return !!this.local.phonenumbers[index][key];
        }
        return true;
    },
    getError: function(field) {
        if (this.requiredCustomFields.includes(field)) {
            return this.isValid(field) ? "" : "Dit veld is verplicht";
        }
        return "";
    },
    isAddressValid: function(_, index) {
          return this.requiredAddressFields.every(f => this.isValid(f, index));
    },
    isPhonenumberValid: function(_, index) {
        return this.requiredPhonenumberFields.every(f => this.isValid(f, index));
    },
    updateContactInfo: async function(input) {
        this.isLoading = true;
        try {
            await this.$store.dispatch("updateContactInfo", input);
            this.$refs.confirmationModal.toggle();
            this.isLoading = false;
        }
        catch(err) {
            this.$refs.errorModal.toggle();
            this.isLoading = false;
        }
    },
    detect: function(fieldtype) {
        if (["int", "decimal", "string"].includes(fieldtype)) {
            return "InputField";
        }
        if (fieldtype === "date") {
            return "DateField";
        }
        if (fieldtype === "boolean") {
            return "CheckBoxField";
        }
        if (fieldtype.startsWith('select')) {
            return "SelectField";
        }
        return "InputField";
    },
    shouldShowCustomField(field) {
        if (!R.path([field.key, 'condition'], this.formConfig.customfields)) {
            return true;
        }
        return this.formConfig.customfields[field.key].condition(this.local.contact, this.$auth.contactinfo);
    },
    getLabelOfCustomField: function(field) {
        if (!R.path([field.key, 'label'], this.formConfig.customfields)) {
            return field.caption;
        }
        return this.formConfig.customfields[field.key].label[this.config.locale];
    },
    getDescriptionOfCustomField: function(field) {
        if (!R.path([field.key, 'description'], this.formConfig.customfields)) {
            return '';
        }
        return this.formConfig.customfields[field.key].description[this.config.locale];
    },
    isRequiredCustomField: function(field) {
        if (!this.formConfig.customfields[field.key]) {
            return false;
        }
        if (this.formConfig.customfields[field.key].required === undefined) {
            return false;
        }        
        return this.formConfig.customfields[field.key].required;
    },    
  },
  watch: {
    contact: {
      handler() {
          this.local = R.clone(this.contact);
      },
      immediate: true,
    },
    local: {
        handler() {
            if (this.local.contact.birthdate === "") {
                this.local.contact.birthdate = null;
            }
            this.dirty = !R.equals(this.local, this.contact);
        },
        deep: true,
    },
    birthdatePreferNotToSay(newValue) {
        if (newValue) {
            this.local.contact.birthdate = null;
        }
    },
  },
  async created() {
      const shortname = this.config.shortname;
      this.isLoading = true;
        try {
            if (!this.$store.state.contact.contact) {
                await this.$store.dispatch('setContactInfo');
            }
            if (this.$store.state.contactconfig.contacttitles.length === 0) {
                await this.$store.dispatch('setContactConfig');
            }
            if (this.$store.state.customfields.loaded !== true) {
                await this.$store.dispatch('setCustomFields');
            }
            const configModule = await import(`@/config/contactinfo/${shortname}.js`);
            this.formConfig = configModule.default;            
        } catch (ex) {
            this.error = true;
        } finally {
            this.isLoading = false;
        }      
  },
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "Contactinfo",
    })
  },
}
</script>
