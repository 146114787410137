<template>
    <div>
      <label :for="id" class="px-1 text-sm text-gray-600 font-bold">
        {{ label }}
        <span v-if="required">*</span>
      </label>
      <input :class="['text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none', {'border-red-500': error}]"
             :id="id"
             type="date"
             :value="formattedValue"
             @input="emitValue($event.target.value)" />
      <span class="text-gray-600 text-xs mt-1">
        {{ description }}
      </span>
      <div v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</div>
    </div>
</template>
  
<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedValue() {
      if (this.value === null || this.value === '') {
        return '';
      }
      return this.value.split(' ')[0];
    }
  },
  methods: {
      emitValue(value) {
          if (value === '') {
              this.$emit('input', null);
          } else {
              this.$emit('input', value);
          }
      }
  }
}
</script>
