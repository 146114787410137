<template>
    <div class="flex flex-col">
        <div class="flex items-baseline">
            <input class="form-checkbox text-md block px-3 py-2 rounded-lg bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                :id="id"
                type="checkbox"
                :class="inputClasses"
                :checked="value"
                @input="$emit('input', $event.target.checked)"
            />
            <label :for="id" class="ml-2 px-1 text-sm text-gray-600 font-bold">
                {{ label }}
                <span v-if="required">*</span>
            </label>
        </div>
        <span class="text-gray-600 text-xs mt-1">
            {{ description }}
        </span>        
        <div v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: Boolean
        },
        description: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    computed: {
        inputClasses() {
            return {
                "border-red-500": this.error
            }
        }
    }
}
</script>